<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <!-- <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Locations</li>
            </ol> -->
            <div class="d-flex">
              <label class="col-form-label me-2">Employee Type</label>
              <div style="width: 150px">
                <v-select
                  label="name"
                  v-model="type"
                  :options="types"
                  :clearable="false"
                  :reduce="(name) => name.id"
                  @option:selected="getPlansByEmployeeType($event)"
                >
                </v-select>
              </div>
            </div>
            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Monthly Plan Calendar View</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <FullCalendar :options="calendarOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <EnrollModal :modalId="'monthly-plan-modal'">
    <template v-slot:header> Module Detail </template>
    <template v-slot:body>
      <div class="card-body" style="padding: 0px !important">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap table-hover mb-0">
            <tbody>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Module Name:</span>
                </th>
                <td>
                  <span class="ms-1 custom-block">{{ module_name }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Employee Type:</span>
                </th>
                <td>
                  <span class="ms-1">{{ employee_type }}</span>
                </td>
              </tr>
              <tr class="custom-tr" v-if="selected_module_name">
                <th>
                  <span class="ms-1">Level:</span>
                </th>
                <td>
                  <div
                    class="ms-1"
                    v-for="(name, index) in selected_module_name"
                    :key="index"
                  >
                    {{ name }}
                  </div>
                </td>
              </tr>
              <tr class="custom-tr" v-if="selectedProgram">
                <th>
                  <span class="ms-1">Program:</span>
                </th>
                <td>
                  <div
                    class="ms-1">
                    {{ selectedProgram.short_name }}
                  </div>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Module Group:</span>
                </th>
                <td>
                  <span class="ms-1 custom-block">{{ module_group }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th style="vertical-align: top !important">
                  <span class="ms-1">Trainer Name:</span>
                </th>
                <td>
                  <!-- style="display: block" -->
                  <span
                    class="ms-1 custom-block"
                    v-for="(trainer, index) in trainers"
                    :key="index"
                    >{{ trainer.name }}
                    <span v-if="index + 1 < trainers.length">, </span>
                  </span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Learning Objective:</span>
                </th>
                <td>
                  <span class="ms-1 custom-css">{{ learning_objective }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Course Description:</span>
                </th>
                <td>
                  <span class="ms-1 custom-css">{{ description }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">From Date:</span>
                </th>
                <td>
                  <span class="ms-1">{{ monthly_plan.from_date }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">To Date:</span>
                </th>
                <td>
                  <span class="ms-1">{{ monthly_plan.to_date }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Enrolled Expired Date:</span>
                </th>
                <td>
                  <span class="ms-1">{{ monthly_plan.expired_date }}</span>
                </td>
              </tr>
              <!-- <tr class="custom-tr">
                <th>
                  <span class="ms-1">Training Hour:</span>
                </th>
                <td>
                  <span class="ms-1">{{ duration }}</span>
                </td>
              </tr> -->
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Start Time:</span>
                </th>
                <td>
                  <span class="ms-1">{{ monthly_plan.start_time }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Price:</span>
                </th>
                <td>
                  <span class="ms-1">{{ price }}</span>
                </td>
              </tr>
              <tr class="custom-tr">
                <th>
                  <span class="ms-1">Location:</span>
                </th>
                <td>
                  <span class="ms-1 custom-block">{{
                    monthly_plan.location
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center mx-auto">
        <button
          v-if="monthly_plan.is_plan_confirmed == 4"
          type="button"
          class="btn btn-success waves-effect waves-light me-4"
          data-bs-toggle="tooltip"
          title="Try Later! Training plan is cancelled."
          style="cursor: not-allowed"
        >
          Enroll
        </button>
        <button
          v-else-if="monthly_plan.is_plan_confirmed == 0"
          type="button"
          class="btn btn-success waves-effect waves-light me-4"
          data-bs-toggle="tooltip"
          title="Try Later ! Training plan is rejected because of less enrollment."
          style="cursor: not-allowed"
        >
          Enroll
        </button>
        <button
          v-else-if="monthly_plan.expired_date < current_year_month"
          type="button"
          class="btn btn-success waves-effect waves-light me-4"
          data-bs-toggle="tooltip"
          title="Your enrollment date is expired."
          style="cursor: not-allowed"
        >
          Enroll
        </button>
        <button
          v-else
          type="button"
          class="btn btn-success waves-effect waves-light me-4"
          data-bs-dismiss="modal"
          @click="toEnroll(monthly_plan.id)"
        >
          Enroll
        </button>
        <button
          type="button"
          class="btn w-sm btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
      </div>
    </template>
  </EnrollModal>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "axios";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import EnrollModal from "../../components/shared/Modal.vue";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    EnrollModal,
    vSelect,
  },
  data() {
    return {
      loading: false,
      monthly_plan: "",
      type: "",
      types: [],
      module_name: "",
      employee_type: "",
      module_group: "",
      selected_module_name: [],
      // duration: "",
      position_levels_id: [],
      programs: [],
      selectedProgram: "",
      price: "",
      trainers: "",
      learning_objective: "",
      description: "",
      current_year_month: "",
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      filterDatas: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        events: null, // alternatively, use the `events` setting to fetch from a feed
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.showPlanDetailModal,
        eventsSet: this.handleEvents,
        height: "auto",
      },
    };
  },
  methods: {
    async fethAllMonthlyPlans() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/monthly-plan-calender`)
        .then((result) => {
          this.filterDatas = result.data.data;
          this.calendarOptions.events = result.data.data;
          console.log(this.calendarOptions.events, "calendar");
        });
      this.loading = false;
    },
    async getEmployeeTypes() {
      await axios
        .get(`${this.baseUrl}admin/v1/employee-types`)
        .then((response) => {
          this.types = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Employee Type");
        });
    },
    toggleWeekends: function () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
    },
    async showPlanDetailModal(value) {
      await axios
        .get(
          `${this.baseUrl}admin/v1/show-monthly-plan-detail/${value.event.id}`
        )
        .then((response) => {
          this.monthly_plan = response.data.data;
          this.module_name = this.monthly_plan.module.name;
          this.employee_type = this.monthly_plan.employee_type.name;
          this.module_group = this.monthly_plan.group.name;
          // this.duration = this.monthly_plan.module.duration;
          this.price = this.monthly_plan.module.price;
          this.trainers = this.monthly_plan.trainers;
          this.learning_objective = this.monthly_plan.module.learning_objective;
          this.description = this.monthly_plan.module.description;
        });
      const level = JSON.parse(this.monthly_plan.group.position_levels);

      // Create a new array to store the matching level names
      if(level == null || level == "" || level == []) {
        this.selected_module_name = null;
        this.selectedProgram = this.programs.find((item) => {
          if(item.id === this.monthly_plan.group.program_id) {
            return item;
          }
        })
      } else {
        this.selectedProgram = "";
        const matchingNames = level.map((id) => {
          const foundLevel = this.position_levels_id.find(
            (item) => item.id === id
          );
          return foundLevel ? foundLevel.name : null;
        });
        // Filter out any null values (in case an id in positionlevel doesn't exist in level)
        this.selected_module_name = matchingNames.filter(
          (name) => name !== null
        );

        // Join the names into a comma-separated string

        // const resultString = filteredNames.join(",");
        // this.selected_module_name = resultString;
      }

      $("#monthly-plan-modal").modal("show");
    },
    toEnroll(id) {
      this.$router.push({ name: "enroll-create", params: { id: id } });
    },
    getPlansByEmployeeType(value) {
      this.calendarOptions.events = this.filterDatas.filter((item) => {
        if (value.id == item.employee_type_id) {
          return item;
        }
      });
    },
    async getPositionLevels() {
      await axios
        .get(`${this.baseUrlHRIS}api/position/level`)
        .then((response) => {
          this.position_levels_id = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Position Levels!");
        });
    },
    async getPrograms() {
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    refreshData() {
      this.type = "";
      this.filterDatas = [];
      this.fethAllMonthlyPlans();
      this.getEmployeeTypes();
      this.current_year_month = moment().format("YYYY-MM-DD");
    },
  },
  created() {
    this.getPositionLevels();
    this.getPrograms();
    this.fethAllMonthlyPlans();
    this.getEmployeeTypes();
    this.current_year_month = moment().format("YYYY-MM-DD");
  },
};
</script>

<style scoped>
.custom-tr {
  border-style: hidden !important;
}

.table > :not(caption) > * > * {
  padding: 0.45rem 0.85rem;
}

.custom-css {
  display: inline-block;
  /* max-width: 230px; */
  max-height: 85px;
  overflow-y: scroll;
  white-space: pre-line;
}

.custom-css::-webkit-scrollbar {
  width: 5px;
}

.custom-css::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-css::-webkit-scrollbar-thumb {
  background: #ccc;
}

.custom-block {
  display: block;
  white-space: pre-wrap;
}
</style>